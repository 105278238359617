import {
  CommonItemComboboxSelect,
  ItemComboBoxGenericValuePlaceholder,
  ItemComboboxSelectProps,
} from './CommonItemComboboxSelect'
import { useIntegration, useIntegrationApp } from '@integration-app/react'
import { ComboBoxOptionType } from '@integration-app/ui/ComboBox/types'
import { IntegrationAppClient } from '@integration-app/sdk'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import { IntegrationIcon } from '@integration-app/ui'

export class IntegrationsListOptionFactory {
  private cache: Record<string, ComboBoxOptionType[]> = {}

  constructor(
    private client: IntegrationAppClient,
    private useId = false,
  ) {}

  public async getOptions({ input }): Promise<ComboBoxOptionType[]> {
    if (this.cache[input]) {
      return this.cache[input]
    }

    const { items } = await this.client.integrations.find({
      search: input,
    })

    const options = items.map((integration) => ({
      value: this.useId ? integration.id : integration.key,
      label: (
        <div className='flex items-center gap-2'>
          <IntegrationIcon integration={integration} variant='rounded' />
          <span>{integration.name}</span>
        </div>
      ),
    })) as ComboBoxOptionType[]

    this.cache[input] = options

    return options
  }
}

export function IntegrationComboboxSelect({
  value,
  onChange,
  editable = true,
  hasRightSibling = false,
  useId = false,
  deletable = true,
  hasTitle = true,
}: ItemComboboxSelectProps<string | undefined> & {
  useId?: boolean
  hasTitle?: boolean
}) {
  const client = useIntegrationApp()

  return (
    <CommonItemComboboxSelect<string | undefined>
      value={value}
      onChange={onChange}
      optionFactories={[new IntegrationsListOptionFactory(client, useId)]}
      hasRightSibling={hasRightSibling}
      editable={editable}
      deletable={deletable}
      valueComponent={
        <ComboboxIntegrationValueComponent value={value} hasTitle={hasTitle} />
      }
      valuePlaceholderComponent={
        <ItemComboBoxGenericValuePlaceholder title='Integration' />
      }
    />
  )
}

function ComboboxIntegrationValueComponent({
  value,
  hasTitle = true,
}: {
  value: string | undefined
  hasTitle?: boolean
}) {
  const { integration, loading } = useIntegration(value)

  return (
    <div className='flex items-center gap-1'>
      {hasTitle && <span>Integration: </span>}

      {loading && <span>Loading...</span>}

      {integration && !loading && (
        <>
          <IntegrationIcon integration={integration} variant='rounded' />
          <span>{integration.name}</span>
          <SvgIcon
            type={SvgIconType.ChevronDown}
            style={{
              width: 14,
              strokeWidth: 2,
            }}
          />
        </>
      )}
    </div>
  )
}
