import useFlowContext from 'contexts/flow-context'
import { Flow, IntegrationAppError } from '@integration-app/sdk'
import useFlowPreviewContext from '../../../../../components/Docs/FlowPreview/flow-preview-context'
import { useFlowInstanceContext } from 'contexts/flow-instance-context'
import { routeTo } from '../../routes-constants'
import { useIntegrationContext } from '../../ExternalApps/Integrations/Integration/contexts/integration-context'

interface IGenericFlowContext {
  flow: Flow

  patchFlow(data: Record<string, any>): Promise<void>
  putFlow(data: Flow): Promise<void>
  patchNode(key: string, data: Record<string, any>): Promise<void>
  patchNodeConfig(nodeKey: string, data: Record<string, any>): Promise<void>

  archiveFlow(): Promise<void>

  refresh(): Promise<Flow>

  isSaving: boolean
  error: null | IntegrationAppError
  flowRoute: string
}

/*
 * This hook should be used inside the FlowBuilder related components to ensure generic logic.
 */
export function useGenericFlow(): IGenericFlowContext {
  const flowContext = useFlowContext()
  const flowInstanceContext = useFlowInstanceContext()
  const flowPreviewContext = useFlowPreviewContext()
  const { integration } = useIntegrationContext()

  if (flowContext.flow) {
    const {
      flow,
      onChange,
      archive,
      patchNode,
      patchNodeConfig,
      refresh,
      isSaving,
      error,
    } = flowContext

    return {
      flow,
      patchFlow: onChange,
      putFlow: onChange,
      archiveFlow: archive,
      patchNode,
      patchNodeConfig,
      refresh,
      isSaving,
      error,
      flowRoute: routeTo.flow(flow, { toIntegrationSpecific: !!integration }),
    }
  }

  if (flowInstanceContext.flowInstance) {
    // This is hacky - need to implement proper common context for flow and flowInstance
    return {
      flow: flowInstanceContext.flowInstance as any as Flow,
      patchFlow: flowInstanceContext.onChange,
      putFlow: flowInstanceContext.onChange,
      archiveFlow: flowInstanceContext.archive,
      patchNode: flowInstanceContext.patchNode,
      patchNodeConfig: flowInstanceContext.patchNodeConfig,
      refresh: flowInstanceContext.refresh as any,
      isSaving: false, // ToDo: implement
      error: null, // ToDo implement
      flowRoute: routeTo.flowInstance(flowInstanceContext.flowInstance?.id),
    }
  }

  if (flowPreviewContext.flowNodes) {
    const { flowNodes } = flowPreviewContext

    return {
      flow: { id: '', name: '', key: '', revision: '', nodes: flowNodes },
      patchFlow: () => Promise.resolve(),
      putFlow: () => Promise.resolve(),
      archiveFlow: () => Promise.resolve(),
      patchNode: () => Promise.resolve(),
      patchNodeConfig: () => Promise.resolve(),
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2322): Type 'Promise<null>' is not assignable to type 'Pr... Remove this comment to see the full error message
      refresh: () => Promise.resolve(null),
      isSaving: false,
      error: null,
    }
  }

  throw new Error(
    `useGenericFlow() should be used inside the <FlowContext /> or <FlowPreviewContext />.`,
  )
}
