import { Popup } from 'routes/components/Popup'
import { useIntegration } from '@integration-app/react'
import { IconButton } from '@integration-app/ui/Button'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'

export function StepHeader({
  integrationId,
  elementName,
  onBack,
}: {
  integrationId?: string
  elementName: string
  onBack(): void
}) {
  const { integration } = useIntegration(integrationId)

  const title = !integrationId
    ? `Create Universal ${elementName}`
    : !integration?.name
      ? `Create ${elementName}`
      : `Create ${elementName} for ${integration.name}`

  return (
    <Popup.Header>
      <h2 className={'flex flex-row gap-1 -ml-2'}>
        <IconButton tooltip={`Back to previous stem`} onClick={onBack}>
          <SvgIcon type={SvgIconType.ChevronLeft} />
        </IconButton>

        {title}
      </h2>
    </Popup.Header>
  )
}
