import { IntegrationElementLevel } from '@integration-app/sdk'
import { useGenericConfig } from '../contexts/generic-config-context'
import { UniversalImportFieldMappingUnitConfig } from './UniversalImportFieldMappingUnitConfig'
import { ConnectionLevelImportFieldMappingUnitConfig } from './ConnectionLevelImportFieldMapping'
import {
  NodeConfigurationErrorData,
  NodeConfigurationErrorsGetterArgs,
} from '../../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/types'
import { getBrokenVariablesConfigurationErrors } from '../../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/getBrokenVariablesConfigurationErrors'
import { IntegrationLevelImportFieldMappingUnitConfig } from './IntegrationLevelImportFieldMappingUnitConfig copy'

export function ImportFieldMappingUnitConfig() {
  const { level } = useGenericConfig()

  switch (level) {
    case IntegrationElementLevel.UNIVERSAL:
      return <UniversalImportFieldMappingUnitConfig />
    case IntegrationElementLevel.CONNECTOR:
      return <IntegrationLevelImportFieldMappingUnitConfig />
    case IntegrationElementLevel.CONNECTION:
      return <ConnectionLevelImportFieldMappingUnitConfig />
    default:
      return null
  }
}

export function getImportFieldMappingConfigErrors({
  node,
  dataCollectionSpec,
  udmSpec,
}: NodeConfigurationErrorsGetterArgs): NodeConfigurationErrorData[] {
  const fieldMappingConfig = node.config?.fieldMapping ?? {}

  if (fieldMappingConfig.key) {
    return []
  }

  const universalFieldsErrors = getBrokenVariablesConfigurationErrors(
    fieldMappingConfig.defaultUnifiedValue ?? fieldMappingConfig.default,
    [udmSpec?.fieldsSchema],
  )

  const integrationSpecificFieldsErrors = getBrokenVariablesConfigurationErrors(
    fieldMappingConfig.defaultValue,
    [dataCollectionSpec?.fieldsSchema],
  )

  return [...universalFieldsErrors, ...integrationSpecificFieldsErrors]
}
