/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Limits",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "API",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Flow Runs",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "External Event Pulls",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Custom Code",
    "attributes": {},
    "children": []
  }]
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "limits",
      children: "Limits"
    }), "\n", _jsx(_components.p, {
      children: "To ensure fair use of our cloud resources, we have implemented a number of limits."
    }), "\n", _jsx(_components.p, {
      children: "If you use a self-hosted version of integration.app, you can change any of these limits by providing corresponding environment variables."
    }), "\n", _jsx(_components.h2, {
      id: "api",
      children: "API"
    }), "\n", _jsx("table", {
      className: 'stripe w-full mb-4',
      children: _jsxs("tbody", {
        children: [_jsxs("tr", {
          children: [_jsx("td", {
            children: "Maximum request size"
          }), "\n", _jsx("td", {
            children: "10 Mb"
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: "Maximum response size"
          }), "\n", _jsx("td", {
            children: "30 Mb"
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: "Maximum request duration"
          }), "\n", _jsx("td", {
            children: "60 seconds"
          })]
        })]
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["If you need to work with larger data, please check ", _jsx(DocLink, {
          path: "engine/advanced/binary-data"
        }), "."]
      }), "\n", _jsxs(_components.li, {
        children: ["If you need to run longer requests, use ", _jsx(DocLink, {
          path: "engine/blueprints/flows",
          children: "Flows"
        }), "."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "flow-runs",
      children: "Flow Runs"
    }), "\n", _jsx("table", {
      className: 'stripe w-full',
      children: _jsxs("tbody", {
        children: [_jsxs("tr", {
          children: [_jsx("td", {
            children: "Maximum flow run duration"
          }), "\n", _jsx("td", {
            children: "50 min"
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: "Maximum memory size"
          }), "\n", _jsx("td", {
            children: "2 GB"
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: "Maximum flow node output"
          }), "\n", _jsx("td", {
            children: "20 MB"
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: "Maximum parallel flow runs per connection"
          }), "\n", _jsx("td", {
            children: "1"
          })]
        })]
      })
    }), "\n", _jsx(_components.h2, {
      id: "external-event-pulls",
      children: "External Event Pulls"
    }), "\n", _jsx("table", {
      className: 'stripe w-full mb-4',
      children: _jsxs("tbody", {
        children: [_jsxs("tr", {
          children: [_jsx("td", {
            children: "Maximum parallel incremental pulls per connection"
          }), "\n", _jsx("td", {
            children: "1"
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: "Maximum parallel \"full sync\" pulls per connection"
          }), "\n", _jsx("td", {
            children: "1"
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: "Maximum parallel pulls per workspace"
          }), "\n", _jsx("td", {
            children: "10"
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: "Minimal interval for incremental pull"
          }), "\n", _jsx("td", {
            children: "60 seconds"
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: "Minimal interval for \"full sync\" pull"
          }), "\n", _jsx("td", {
            children: "60 minutes"
          })]
        })]
      })
    }), "\n", _jsx(_components.h2, {
      id: "custom-code",
      children: "Custom Code"
    }), "\n", _jsx("table", {
      className: 'stripe w-full mb-4',
      children: _jsxs("tbody", {
        children: [_jsxs("tr", {
          children: [_jsx("td", {
            children: "Maximum memory when runnign custom code"
          }), "\n", _jsx("td", {
            children: "512 MB"
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: "Maximum duration of custom code run in synchronous mode"
          }), "\n", _jsx("td", {
            children: "50 seconds"
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: "Maximum duration of custom code run in asynchronous mode (flow)"
          }), "\n", _jsx("td", {
            children: "50 minutes"
          })]
        })]
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
