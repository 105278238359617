import {
  getDataCollectionCreateFields,
  getDataCollectionUpdateFields,
  schemaWithTitle,
} from '@integration-app/sdk'
import { makeDataField } from '@integration-app/ui/DataBuilder'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { CollapsibleSection } from '../../Section'
import { useGenericConfig } from '../contexts/generic-config-context'

import { SimpleRadio } from '@integration-app/ui'
import { SharedExportFieldMapping } from './SharedExportFieldMapping'
import { GenerateFieldMappingButton } from '../../../Blueprints/FieldMappings/FieldMapping/components/GenerateFieldMappingButton'

const DEFAULT_SCHEMA = {
  type: 'object',
  additionalProperties: true,
}

enum ExportFieldMappingUnitConfigOption {
  Custom = 'custom',
  Shared = 'shared',
}

export function IntegrationLevelExportFieldMappingUnitConfig({
  isUpdate = false,
}: {
  isUpdate?: boolean
}) {
  const {
    patchConfig,
    fieldMappingConfig,
    patchFieldMappingConfig,
    dataCollectionSpec,
    variablesSchema,
    editableVariablesSchemaLocators,
    handleAddVariable,
  } = useGenericConfig()

  async function handleSelectOption(
    option: ExportFieldMappingUnitConfigOption,
  ) {
    switch (option) {
      case ExportFieldMappingUnitConfigOption.Custom:
        return patchConfig({
          fieldMapping: {
            defaultValue: fieldMappingConfig.defaultValue,
          },
        })
      case ExportFieldMappingUnitConfigOption.Shared:
        return patchConfig({
          fieldMapping: {
            key: null,
          },
        })
    }
  }

  async function handleMappingChange(value: any) {
    return patchFieldMappingConfig({
      defaultValue: value,
    })
  }

  let selectedOption = ExportFieldMappingUnitConfigOption.Custom

  if (fieldMappingConfig?.key !== undefined) {
    selectedOption = ExportFieldMappingUnitConfigOption.Shared
  }

  const integrationSpecificSchema = isUpdate
    ? getDataCollectionUpdateFields(dataCollectionSpec)
    : getDataCollectionCreateFields(dataCollectionSpec)

  const fieldMappingTargetSchema = schemaWithTitle(
    integrationSpecificSchema ?? DEFAULT_SCHEMA,
    'Fields',
  )

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>Fields</CollapsibleSection.Header>

      <SimpleRadio
        label='Provide fields inline'
        checked={selectedOption === ExportFieldMappingUnitConfigOption.Custom}
        onCheck={() =>
          handleSelectOption(ExportFieldMappingUnitConfigOption.Custom)
        }
      />
      <SimpleRadio
        label='Use shared field mapping config'
        checked={selectedOption === ExportFieldMappingUnitConfigOption.Shared}
        onCheck={() =>
          handleSelectOption(ExportFieldMappingUnitConfigOption.Shared)
        }
      />

      {selectedOption === ExportFieldMappingUnitConfigOption.Shared && (
        <SharedExportFieldMapping />
      )}

      {selectedOption === ExportFieldMappingUnitConfigOption.Custom && (
        <div>
          <p>
            These values will be used by default when the integration is
            enabled. They can be changed later in the User Interface.
          </p>
          {fieldMappingTargetSchema && variablesSchema?.properties?.input && (
            <GenerateFieldMappingButton
              fromSchema={variablesSchema}
              toSchema={fieldMappingTargetSchema}
              onGenerate={handleMappingChange}
            />
          )}

          <DataBuilderForm
            onChange={handleMappingChange}
            field={makeDataField({
              schema: fieldMappingTargetSchema,
              value: fieldMappingConfig.defaultValue,
              variablesSchema: variablesSchema,
            })}
            editableVariablesSchemaLocators={editableVariablesSchemaLocators}
            onAddVariable={handleAddVariable}
          />
        </div>
      )}
    </CollapsibleSection>
  )
}
