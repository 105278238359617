import { CollapsibleSection } from '../../Section'
import { useGenericConfig } from '../contexts/generic-config-context'
import { CustomImportFieldMapping } from './CustomImportFieldMapping'

export function ConnectionLevelImportFieldMappingUnitConfig() {
  const { patchFieldMappingConfig, fieldMappingConfig, dataCollectionSpec } =
    useGenericConfig()

  const fieldsSchema = {
    type: 'object',
    additionalProperties: true,
  }

  const isSharedFieldMapping = !!fieldMappingConfig?.key

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>Field Mapping</CollapsibleSection.Header>
      <p className='mb-4'>
        Configure the contents of the <code>fields</code> property of data
        records returned by this action.
      </p>

      {isSharedFieldMapping ? (
        <p>
          This step uses a shared field mapping config. Please edit it there.
        </p>
      ) : (
        <CustomImportFieldMapping
          value={fieldMappingConfig?.importValue}
          onChange={(importValue) => patchFieldMappingConfig({ importValue })}
          internalFieldsSchema={fieldsSchema}
          externalFieldsSchema={dataCollectionSpec?.fieldsSchema}
        />
      )}
    </CollapsibleSection>
  )
}
