import {
  getDataCollectionCreateFields,
  getDataCollectionUpdateFields,
  schemaWithTitle,
} from '@integration-app/sdk'
import { makeDataField } from '@integration-app/ui/DataBuilder'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { CollapsibleSection } from '../../Section'
import { useGenericConfig } from '../contexts/generic-config-context'

import { GenerateFieldMappingButton } from '../../../Blueprints/FieldMappings/FieldMapping/components/GenerateFieldMappingButton'

const DEFAULT_SCHEMA = {
  type: 'object',
  additionalProperties: true,
}

export function ConnectionLevelExportFieldMappingUnitConfig({
  isUpdate = false,
}: {
  isUpdate?: boolean
}) {
  const {
    fieldMappingConfig,
    patchFieldMappingConfig,
    dataCollectionSpec,
    variablesSchema,
    editableVariablesSchemaLocators,
    handleAddVariable,
  } = useGenericConfig()

  async function handleMappingChange(value: any) {
    return patchFieldMappingConfig({
      exportValue: value,
    })
  }

  const isSharedFieldMapping = !!fieldMappingConfig?.key

  const dataCollectionFieldsSchema = isUpdate
    ? getDataCollectionUpdateFields(dataCollectionSpec)
    : getDataCollectionCreateFields(dataCollectionSpec)

  const fieldMappingTargetSchema = schemaWithTitle(
    dataCollectionFieldsSchema ?? DEFAULT_SCHEMA,
    'Fields',
  )

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>Fields</CollapsibleSection.Header>

      {isSharedFieldMapping ? (
        <p>This step uses a shared field mapping. Please configure it there.</p>
      ) : (
        <div>
          {fieldMappingTargetSchema && variablesSchema?.properties?.input && (
            <GenerateFieldMappingButton
              fromSchema={variablesSchema}
              toSchema={fieldMappingTargetSchema}
              onGenerate={handleMappingChange}
            />
          )}

          <DataBuilderForm
            onChange={handleMappingChange}
            field={makeDataField({
              schema: fieldMappingTargetSchema,
              value: fieldMappingConfig.exportValue,
              variablesSchema: variablesSchema,
            })}
            editableVariablesSchemaLocators={editableVariablesSchemaLocators}
            onAddVariable={handleAddVariable}
          />
        </div>
      )}
    </CollapsibleSection>
  )
}
