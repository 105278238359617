import { CollapsibleSection } from '../../Section'
import { useGenericConfig } from '../contexts/generic-config-context'
import {
  DataCollectionSelect,
  DataSourceConfigureEvents,
} from '@integration-app/ui'

export function ConnectionLevelDataSourceUnitConfig({
  configureEvents,
}: {
  configureEvents: boolean
}) {
  const { dataSourceConfig, patchConfig, variablesSchema } = useGenericConfig()

  const isSharedDataSource = !!dataSourceConfig?.key

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>Data Source</CollapsibleSection.Header>
      <p>
        Select which data in the external application do you want to use in this
        integration.
      </p>

      {isSharedDataSource ? (
        <p>This step uses a shared data source config. Please edit it there.</p>
      ) : (
        <>
          <div className='mb-2'>
            <DataCollectionSelect
              value={dataSourceConfig}
              onChange={(dataSource) => patchConfig({ dataSource })}
              variablesSchema={variablesSchema}
            />
          </div>
          <div>
            {configureEvents && (
              <DataSourceConfigureEvents
                value={dataSourceConfig}
                onChange={(dataSource) => patchConfig({ dataSource })}
              />
            )}
          </div>
        </>
      )}
    </CollapsibleSection>
  )
}
